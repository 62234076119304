<script setup lang="ts">
import {AlgoliaCategory} from "~/types/AlgoliaResult";
import {
    getSmallestThumbnailUrl,
    getTranslatedProperty,
} from "@shopware-pwa/helpers-next";

const props = defineProps<{
    category: AlgoliaCategory;
}>();

const categoryImg = computed((): string|undefined => {
    return getSmallestThumbnailUrl(props.category.media)
});

const {category} = toRefs(props);
</script>
<template>
<div
    class="py-2 flex items-center gap-3 bg-white px-8 cursor-pointer h-fit"
>
    <div class="border-1 w-20 border-gray-200 overflow-hidden flex flex-none" v-if="category.hasOwnProperty('image') && categoryImg">
        <NuxtImg
            :src="categoryImg"
            :alt="category.name"
            height="64"
            class="h-16 mx-auto"
            loading="lazy"
        />
    </div>
    <div class="flex flex-col sm:flex-row justify-between w-full">
        <div class="flex items-center justify-between overflow-hidden gap-5 grow">
            <div
                data-test-id="layout-search-suggest-name"
                class="overflow-hidden text-ellipsis normal-case text-gray-500 text-sm"
                :class="{
                      'pl-4': !(category.hasOwnProperty('image'))
                    }"
            >
                <div>
                    {{ getTranslatedProperty(category, "name") }}
                </div>
            </div>
        </div>
    </div>
</div>
</template>
